// ELEMENTS:

const toast = document.getElementById('toast');

const contextualToasts = {};

// CONSTANTS:

const TOAST_DURATION = 1500;


// STATE:

let toastTimeoutID = 0;

export function showToast(message) {
  clearTimeout(toastTimeoutID);

  toast.textContent = message;

  toastTimeoutID = setTimeout(() => {
    toast.textContent = '';
  }, TOAST_DURATION);
}

export function clearToast() {
  toast.textContent = '';
}

export function showContextualToast(message, parent) {
  const contextualToast = document.createElement('DIV');
  const isKeyInspectorCell = parent.classList.contains('summary__button');
  const isKeycodeNumber = parent.classList.contains('description') && !Number.isNaN(parseInt(parent.textContent, 10));

  contextualToast.style = `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: ${ isKeyInspectorCell ? 12 : 8 }px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: ${ isKeycodeNumber ? 'flex-end' : 'flex-start' };
    background: ${ isKeyInspectorCell ? '#FFFFFF' : '#FFFAFA' };
  `;

  contextualToast.textContent = message;

  parent.appendChild(contextualToast);

  const contextualToastTimeoutID = setTimeout(() => {
    contextualToast.remove();
    delete contextualToasts[contextualToastTimeoutID];
  }, TOAST_DURATION);

  contextualToasts[contextualToastTimeoutID] = contextualToast;
}

export function clearContextualToast() {
  Object.keys(contextualToasts).forEach((contextualToastKey) => {
    contextualToasts[contextualToastKey].remove();
    delete contextualToasts[contextualToastKey];
  });
}
