import { updateKeys, setAltCodeMode, setNormalMode, setVirtualKeyboardMode } from './components/controls/controls.component';
import { updateValuesDown, updateValuesPress, updateValuesUp } from './components/sections/event-inspector/table.components';
import { updateKeyInspector } from './components/sections/key-inspector/key-inspector.component';
import { initNavigation } from './components/app/app.component';
import { log } from './components/log/log.component';
import { showToast } from './components/toast/toast.component';

import './components/copy/copy.component';

const logoImg = document.getElementById('logoImg');
const input = document.getElementById('input');

initNavigation();

let lastKeyboardEvent = 0;

input.addEventListener('input', () => {
  const delta = performance.now() - lastKeyboardEvent;

  // Prevent resetting all controls when holding down (repeating) a key:
  if (delta > 100) {
    requestAnimationFrame(() => {
      updateKeys();
      updateKeyInspector();
      updateValuesDown();
      updateValuesPress();
      updateValuesUp();
      setNormalMode();
    });
  }
});

document.addEventListener('keydown', (e) => {
  lastKeyboardEvent = performance.now();

  log('keydown', e.key, e.code, e);

  requestAnimationFrame(() => {
    updateKeys(e);
    updateKeyInspector(e);
    updateValuesDown(e);
    updateValuesPress();
    updateValuesUp();

    if ((e.key === 'Unidentified' || e.key === 'Process') && e.which === 229) {
      setVirtualKeyboardMode();

      // TODO: This should diff the input value:
      document.getElementById('kKey').innerText = input.value.charAt(input.selectionStart - 1);
    } else {
      if (e.key.toUpperCase() === 'C' && e.ctrlKey) {
        showToast('Double-click cells to copy.');
      } else if (e.key === 'Enter') {
        logoImg.style.transform = 'translate(0, 4px)';
      }

      setNormalMode();
    }
  });
});

document.addEventListener('keypress', (e) => {
  lastKeyboardEvent = performance.now();

  log('keypress', e.key, e.code, e);

  requestAnimationFrame(() => {
    updateValuesPress(e);

    if (e.location === 1 && ['Control', 'Shift', 'Alt', 'Win'].indexOf(e.key) === -1) {
      // IE Alt Code detection
      // - Only fires on key press.
      // - e.key will contain the actual character that has been generated.
      // - e.code is undefined.
      // - We know this is an Alt Code because e.key is not a control key, yet e.location === 1.

      updateKeyInspector(e);
      updateValuesDown();

      setAltCodeMode();
    }
  });
});

document.addEventListener('keyup', (e) => {
  lastKeyboardEvent = performance.now();

  log('keyup', e.key, e.code, e);

  requestAnimationFrame(() => {
    updateValuesUp(e);

    if (e.key === 'PrintScreen') {
      // Only fires on key up.
      updateKeys(e);
      updateKeyInspector(e);
    } else if (e.key !== 'Alt' && e.code === 'AltLeft') {
      // Non-IE Alt Code detection:
      // - Only fires on key press or up.
      // - e.key will contain the actual character that has been generated.
      // - e.code will be AltLeft. It doesn't work with AltRight.

      updateKeyInspector(e);
      updateValuesDown();

      setAltCodeMode();
    } else if (e.key === 'Enter') {
      logoImg.style.transform = 'translate(0, 0)';
    }
  });
});

/*
const beforeInputData = document.getElementById('beforeInputData');
const beforeInputDataLink = beforeInputData.previousElementSibling;
const beforeInputType = document.getElementById('beforeInputType');

document.addEventListener('beforeinput', (e) => {
  // console.log('beforeinput', e.data, e.dataTransfer, e.inputType, e);

  // TODO: This should be reset on other evens:
  beforeInputData.innerText = e.data === null ? e.dataTransfer : e.data;
  beforeInputDataLink.innerText = e.data === null ? 'beforeinput.dataTransfer' : 'beforeinput.data';
  beforeInputType.innerText = e.inputType;

  // if (e.inputType === 'insertText') {
  //   beforeInputData.innerText = e.data;
  // } else {
  //   beforeInputData.innerText = '';
  // }

  // e.preventDefault();
});
*/

/*
document.addEventListener('compositionstart', () => {
  console.log('compositionstart', e);
});

document.addEventListener('compositionend', () => {
  console.log('compositionend', e);
});
*/

// TODO: See https://stackoverflow.com/questions/5072155/how-do-i-get-window-getselection-to-work-for-an-input-type-text-field.

/*
document.addEventListener('selectionchange', (e) => {
  if (document.getSelection().anchorNode === input.parentElement) {
    const characters = input.selectionEnd - input.selectionStart;

    console.log(`${ characters } characters selected`);
  } else {
    console.log('Something else happened to the selection.');
  }

  console.log(e);
});
*/


// Screenshot Mode:

const SCREENSHOT_MODE_CLASS = 'screenshotMode';

window.enableScreenshotMode = () => document.body.classList.add(SCREENSHOT_MODE_CLASS);

window.disableScreenshotMode = () => document.body.classList.remove(SCREENSHOT_MODE_CLASS);


// Version Information:

window.v = () => {
  const buildDate = new Date(process.env.BUILD_DATE);

  return `${ buildDate.toDateString() } at ${ buildDate.toLocaleTimeString() } | ${ process.env.COMMIT_HASH }`;
};


// SERVICE WORKER:

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    if (process.env.DEV) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        // eslint-disable-next-line no-console
        console.log(`Unregistering all ${ registrations.length } ServiceWorkers`);

        registrations.forEach((registration) => registration.unregister());
      });

      return;
    }

    navigator.serviceWorker.register('/service-worker.js');
  });
}

// eslint-disable-next-line no-console
console.log(`Tweet about this! 👉 https://twitter.com/intent/tweet?url=${
  encodeURIComponent('https://keyjs.dev')
}&via=gmzcodes&text=${
  // eslint-disable-next-line max-len
  encodeURIComponent('👇 Press any key to get the JavaScript KeyboardEvent\'s key, code, which, keyCode and more properties!\n\n#javascript #js #keyboard #keys #event #tool #devtool\n\n')
}`);
