import { FAKE_EMPTY_KEYBOARD_EVENT } from '../../utils/events/events.constants';
import { clearContextualToast } from '../toast/toast.component';

import { META_KEYS, SYMBOLS, LOCATION_LABELS, EMPTY_REPEAT_COUNT } from './controls.constants';


// ELEMENTS:

const { documentElement } = document;

const keyInspectorDescription = document.getElementById('keyInspectorDescription');
const eventInspectorDescription = document.getElementById('eventInspectorDescription');
const tableHeader = document.getElementById('tableHeader');
const consoleElement = document.getElementById('console');
const input = document.getElementById('input');

const altCodeButton = document.getElementById('altCodeButton');
const altCodeDescription = document.getElementById('altCodeDescription');
const virtualKeyboardButton = document.getElementById('virtualKeyboardButton');
const virtualKeyboardDescription = document.getElementById('virtualKeyboardDescription');

const kKey = document.getElementById('kKey');
const kShift = document.getElementById('kShift');
const kCtrl = document.getElementById('kCtrl');
const kAlt = document.getElementById('kAlt');
const kMeta = document.getElementById('kMeta');


// STATE:

const isPositionStickySupported = window?.CSS?.supports('position', 'sticky') || false;

let isConsoleFixed = false;
let repeatCount = { ...EMPTY_REPEAT_COUNT };


// FUNCTIONS:

export function updateKeys(e = FAKE_EMPTY_KEYBOARD_EVENT) {
  const { key, repeat, location } = e;
  const isMetaKey = META_KEYS.includes(key);
  const keyId = isMetaKey ? key.toLowerCase() : 'key';

  // Update repeat counter:
  if (!repeat) {
    repeatCount = { ...EMPTY_REPEAT_COUNT };
  } else {
    ++repeatCount[keyId];
  }

  // Update general key symbol (special case, always reset):
  kKey.innerText = isMetaKey ? '' : SYMBOLS[key] || key;

  // Update pressed key label to account for location:
  const keyLocationLabel = LOCATION_LABELS[location];

  kKey.setAttribute('aria-label', `Key${ isMetaKey ? '' : keyLocationLabel }`);

  (!e.shiftKey || key === 'Shift') && kShift.setAttribute('aria-label', `Shift${ key === 'Shift' ? keyLocationLabel : '' }`);
  (!e.ctrlKey || key === 'Control') && kCtrl.setAttribute('aria-label', `Ctrl${ key === 'Control' ? keyLocationLabel : '' }`);
  (!e.altKey || key === 'Alt') && kAlt.setAttribute('aria-label', `Alt${ key === 'Alt' ? keyLocationLabel : '' }`);
  (!e.metaKey || key === 'Meta') && kMeta.setAttribute('aria-label', `Meta${ key === 'Meta' ? keyLocationLabel : '' }`);

  // TODO: Move these key values to constants:
  kKey.setAttribute('aria-pressed', !isMetaKey);
  kShift.setAttribute('aria-pressed', e.shiftKey || key === 'Shift');
  kCtrl.setAttribute('aria-pressed', e.ctrlKey || key === 'Control');
  kAlt.setAttribute('aria-pressed', e.altKey || key === 'Alt');
  kMeta.setAttribute('aria-pressed', e.metaKey || key === 'Meta');

  kKey.setAttribute('data-repeat', repeatCount.key);
  kShift.setAttribute('data-repeat', repeatCount.shift);
  kCtrl.setAttribute('data-repeat', repeatCount.control);
  kAlt.setAttribute('data-repeat', repeatCount.alt);
  kMeta.setAttribute('data-repeat', repeatCount.meta);

  clearContextualToast();
}

const DEFAULT_INPUT_PADDING = 16;

export function setNormalMode() {
  input.style.removeProperty('--paddingRight');

  altCodeButton.classList.add('invisible');
  altCodeButton.setAttribute('aria-expanded', 'false');
  altCodeDescription.setAttribute('hidden', true);

  virtualKeyboardButton.classList.add('invisible');
  virtualKeyboardButton.setAttribute('aria-expanded', 'false');
  virtualKeyboardDescription.setAttribute('hidden', true);
}

export function setAltCodeMode() {
  input.style.setProperty('--paddingRight', `${ altCodeButton.offsetWidth + 1.5 * DEFAULT_INPUT_PADDING }px`);

  altCodeButton.classList.remove('invisible');
}

export function setVirtualKeyboardMode() {
  input.style.setProperty('--paddingRight', `${ virtualKeyboardButton.offsetWidth + 1.5 * DEFAULT_INPUT_PADDING }px`);

  virtualKeyboardButton.classList.remove('invisible');
}

function checkAutoScroll() {
  const header
    = window.location.hash === '#keyboard-events-inspector' ? eventInspectorDescription : keyInspectorDescription;
  const scrollTop = header.offsetTop + header.offsetHeight + 12 + 2;
  const scrollMaxY = window.scrollMaxY || (documentElement.scrollHeight - documentElement.clientHeight);

  if (scrollMaxY >= scrollTop && documentElement.scrollTop < scrollTop) {
    documentElement.scrollTop = scrollTop;

    return true;
  }

  return false;
}

const propertiesSection = document.getElementById('propertiesSection');

let isHeaderVisible = false;

// TODO: hide if we go past the table.

function checkFixedConsole() {
  const { hash } = window.location;

  // TODO: Move to constant:
  if (hash === '#keycodes-table') return;

  // This only applies to the key and event inspectors (sticky header with input):
  // TODO: Compute this using the description bottom instead of console element:
  const distanceToTop = Math.round(consoleElement.offsetTop - documentElement.scrollTop);
  const maxHeight = `calc(100vh - ${ 84 + distanceToTop }px)`;

  altCodeDescription.style.maxHeight = virtualKeyboardDescription.style.maxHeight = maxHeight;

  if (distanceToTop === 0 && !isConsoleFixed) {
    isConsoleFixed = true;

    if (isPositionStickySupported) consoleElement.classList.add('showShadow');
  } else if (distanceToTop > 0 && isConsoleFixed) {
    isConsoleFixed = false;

    if (isPositionStickySupported) consoleElement.classList.remove('showShadow');
  }

  if (hash !== '#keyboard-events-inspector') {
    isHeaderVisible = false;
    consoleElement.classList.remove('withFixedHeader');

    return;
  }

  // This only applies to the event inspector (fixed table header):

  const tableHeaderOffset = Math.max(0, -Math.round(eventInspectorDescription.offsetTop
    + eventInspectorDescription.offsetHeight + 12 + 0 - documentElement.scrollTop));

  const tableBottomOffset = Math.max(0, -Math.round(propertiesSection.offsetTop
    + propertiesSection.offsetHeight - 64 - documentElement.scrollTop - consoleElement.offsetHeight));

  if (!isHeaderVisible && tableHeaderOffset > 0 && tableBottomOffset === 0) {
    isHeaderVisible = true;
    consoleElement.classList.add('withFixedHeader');
  } else if ((isHeaderVisible && tableHeaderOffset === 0) || tableBottomOffset > 0) {
    isHeaderVisible = false;
    consoleElement.classList.remove('withFixedHeader');
  }
}

checkFixedConsole();

altCodeButton.addEventListener('click', () => {
  if (altCodeDescription.hasAttribute('hidden')) {
    altCodeDescription.removeAttribute('hidden');
    altCodeButton.setAttribute('aria-expanded', 'true');
  } else {
    altCodeDescription.setAttribute('hidden', true);
    altCodeButton.setAttribute('aria-expanded', 'false');
  }

  input.focus();
});

virtualKeyboardButton.addEventListener('click', () => {
  if (virtualKeyboardDescription.hasAttribute('hidden')) {
    virtualKeyboardDescription.removeAttribute('hidden');
    virtualKeyboardButton.setAttribute('aria-expanded', 'true');
  } else {
    virtualKeyboardDescription.setAttribute('hidden', true);
    virtualKeyboardButton.setAttribute('aria-expanded', 'false');
  }

  input.focus();
});

input.addEventListener('click', () => {
  checkAutoScroll();
});

document.addEventListener('click', (e) => {
  const { target } = e;

  if (
    altCodeButton === target
      || altCodeDescription === target
      || virtualKeyboardButton === target
      || virtualKeyboardDescription === target
      || altCodeDescription.contains(target)
      || virtualKeyboardDescription.contains(target)
  ) {
    e.preventDefault();
    e.stopPropagation();

    return;
  }

  altCodeButton.setAttribute('aria-expanded', 'false');
  altCodeDescription.setAttribute('hidden', true);

  virtualKeyboardButton.setAttribute('aria-expanded', 'false');
  virtualKeyboardDescription.setAttribute('hidden', true);
});

document.addEventListener('scroll', () => {
  requestAnimationFrame(() => {
    checkFixedConsole();
  });
});

window.addEventListener('resize', () => {
  requestAnimationFrame(() => {
    const hasScrolled = checkAutoScroll();

    if (!hasScrolled) checkFixedConsole();
  });
});

propertiesSection.addEventListener('scroll', () => {
  requestAnimationFrame(() => {
    tableHeader.scrollLeft = propertiesSection.scrollLeft;
  });
});

tableHeader.addEventListener('scroll', () => {
  requestAnimationFrame(() => {
    propertiesSection.scrollLeft = tableHeader.scrollLeft;
  });
});

if (isPositionStickySupported) consoleElement.classList.add('withShadow');
