let logCounter = 16;

/*

const all = [];

window.logAll = () => {
  console.log(all);
  console.dir(all);
};

*/

export function log(eventType, key, code, event) {
  if (++logCounter % 17 === 0) {
    logCounter = 0;
    log('Event Type', 'e.key', 'e.code');
  }

  // eslint-disable-next-line no-console
  const logFunction = event ? console.groupCollapsed : console.log;

  logFunction(`${ event ? '' : '\n' }|  ${ [
    new Date().toISOString().slice(11, -1),
    `            ${ eventType }`.slice(-12),
    `                        ${ key }`.slice(-24),
    `                        ${ code }`.slice(-24),
  ].join('  |  ') }  |`);

  if (event) {
    // all.push([eventType, event.key, event.code, event.which, event.keyCode]);

    // eslint-disable-next-line no-console
    console.log(event);

    // eslint-disable-next-line no-console
    console.groupEnd();
  }
}
