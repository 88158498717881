export const META_KEYS = ['Shift', 'Meta', 'Alt', 'Control'];

export const LOCATION_LABELS = ['', ' L', ' R', ' Numpad'];

export const EMPTY_REPEAT_COUNT = {
  key: 1,
  shift: 1,
  control: 1,
  alt: 1,
  meta: 1,
};


// See http://xahlee.info/comp/unicode_computing_symbols.html

// See "Process" in here: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values

// Full list of media keys: https://www.w3.org/TR/uievents-key/#keys-media-controller

export const SYMBOLS = {
  Enter: '↵',
  Backspace: '⟵',
  Tab: '↹',
  CapsLock: '⇪',
  NumLock: '⇭',
  ScrollLock: '⇳',
  PageUp: '⇞',
  PageDown: '⇟',
  Home: '⤒',
  End: '⤓',
  Insert: '⎀',
  Delete: '⌦',
  ArrowUp: '↑',
  ArrowDown: '↓',
  ArrowLeft: '←',
  ArrowRight: '→',
  ContextMenu: '☰',
  AltGraph: '⎄',
  Clear: '⌧',
  Escape: '⎋',
  PrintScreen: '⎙',
  AudioVolumeMute: '🕨',
  AudioVolumeDown: '🕩',
  AudioVolumeUp: '🕪',
  Pause: '⎉',
  Process: '⮔',
};
