import { FAKE_EMPTY_KEYBOARD_EVENT, DEPRECATED_KEYBOARD_EVENT_PROPS, KEYBOARD_EVENT_PROPS } from '../../../utils/events/events.constants';
import { LAB_COPY, LAB_COPY_DEPRECATED } from '../../../utils/labels/labels.constants';


// ELEMENTS:

const cellElements = KEYBOARD_EVENT_PROPS.reduce((obj, property) => {
  const keyD = `${ property }D`;
  const keyP = `${ property }P`;
  const keyU = `${ property }U`;

  obj[keyD] = document.getElementById(keyD);
  obj[keyP] = document.getElementById(keyP);
  obj[keyU] = document.getElementById(keyU);

  return obj;
}, { });


// STATE:

const hasTooltip = {};


// FUNCTIONS:

function updateValuesColumn(e = FAKE_EMPTY_KEYBOARD_EVENT, columnKey = '') {
  if (!columnKey) return;

  KEYBOARD_EVENT_PROPS.forEach((property) => {
    cellElements[`${ property }${ columnKey }`].innerText = e[property];
  });

  // This should be updated on each key event as some columns might be empty:

  if (!hasTooltip[columnKey]) {
    hasTooltip[columnKey] = true;

    KEYBOARD_EVENT_PROPS.forEach((property) => {
      cellElements[`${ property }${ columnKey }`].title
        = DEPRECATED_KEYBOARD_EVENT_PROPS.includes(property) ? LAB_COPY_DEPRECATED : LAB_COPY;
    });
  }
}

export const updateValuesDown = (e) => updateValuesColumn(e, 'D');
export const updateValuesPress = (e) => updateValuesColumn(e, 'P');
export const updateValuesUp = (e) => updateValuesColumn(e, 'U');
