import { FAKE_EMPTY_KEYBOARD_EVENT } from '../../../utils/events/events.constants';
import { LAB_COPY, LAB_COPY_DEPRECATED } from '../../../utils/labels/labels.constants';

// ELEMENTS:

const summaryKey = document.getElementById('summaryKey');
const summaryCode = document.getElementById('summaryCode');
const summaryWhich = document.getElementById('summaryWhich');
const summaryKeyCode = document.getElementById('summaryKeyCode');


// STATE:

let isInit = false;

// FUNCTIONS:

export function initKeyInspector() {
  if (isInit) return;

  isInit = true;

  summaryKey.title = summaryCode.title = LAB_COPY;
  summaryWhich.title = summaryKeyCode.title = LAB_COPY_DEPRECATED;
}

export function updateKeyInspector(e = FAKE_EMPTY_KEYBOARD_EVENT) {
  summaryKey.textContent = e.key;
  summaryCode.textContent = e.code;
  summaryWhich.textContent = e.which;
  summaryKeyCode.textContent = e.keyCode;
}
