import { LAB_COPY } from '../../utils/labels/labels.constants';
import { showToast, showContextualToast } from '../toast/toast.component';

const vibrate = window.navigator.vibrate.bind(window.navigator) || (() => undefined);

// TODO: This should be long touch on mobile:

document.addEventListener('dblclick', async ({ target }) => {
  let copiableElement = null;

  if (target.title.includes(LAB_COPY)) {
    copiableElement = target;
  } else if (target.parentElement.title.includes(LAB_COPY)) {
    copiableElement = target.parentElement;
  }

  if (copiableElement) {
    const copiedText = copiableElement.textContent || '';

    if (copiedText) {
      try {
        await navigator.clipboard.writeText(copiedText);

        vibrate(150);

        showContextualToast(`Copied!`, copiableElement);
      } catch (err) {
        showToast(`Failed to copy: ${ copiedText }`);

        // eslint-disable-next-line no-console
        console.error(`Failed to copy: ${ copiedText }`, err);
      }
    } else {
      showToast('There\'s nothing to copy here!');
    }
  }
});
