export const FAKE_EMPTY_KEYBOARD_EVENT = {
  key: '',
  code: '',
  repeat: '',
  location: '',
  isComposing: '',
  shiftKey: '',
  ctrlKey: '',
  altKey: '',
  metaKey: '',
  which: '',
  keyCode: '',
  charCode: '',
  keyIdentifier: '',
};

export const DEPRECATED_KEYBOARD_EVENT_PROPS = ['which', 'keyCode', 'charCode', 'keyIdentifier'];

export const KEYBOARD_EVENT_PROPS = Object.keys(FAKE_EMPTY_KEYBOARD_EVENT);

