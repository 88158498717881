import { initKeycodesTable } from '../sections/keycodes/keycodes-table.component';
import { initKeyInspector } from '../sections/key-inspector/key-inspector.component';

// CONSTANTS:

const KEY_INSPECTOR_REF = '';
const EVENT_INSPECTOR_REF = 'keyboard-events-inspector';
const KEYCODES_TABLE_REF = 'keycodes-table';
const SECTION_REFS = [KEY_INSPECTOR_REF, EVENT_INSPECTOR_REF, KEYCODES_TABLE_REF];


// ELEMENTS:

const input = document.getElementById('input');
const keyInspectorButton = document.getElementById('keyInspectorButton');
const eventInspectorButton = document.getElementById('eventInspectorButton');
const keyCodesTableButton = document.getElementById('keyCodesTableButton');
const showMoreButton = document.getElementById('showMore');
const showLessButton = document.getElementById('showLess');

const keyInspectorSection = document.getElementById('key-inspector');
const eventInspectorSection = document.getElementById('keyboard-events-inspector');
const consoleElement = document.getElementById('console');

// STATE:

let currentSectionRef = '';

// FUNCTIONS:

function getHashSectionRef() {
  const hash = window.location.hash.replace('#', '');

  return SECTION_REFS.includes(hash) ? hash : '';
}

function updateNavigation(prevSectionRef, nextSectionRef) {
  currentSectionRef = nextSectionRef;

  if (nextSectionRef === KEYCODES_TABLE_REF) {
    initKeycodesTable();

    if (prevSectionRef === '') {
      // NAV allows: Keycodes table -> Key inspector:
      keyInspectorButton.removeAttribute('hidden');
      eventInspectorButton.setAttribute('hidden', true);
    } else {
      // NAV allows: Keycodes table -> Event inspector:
      keyInspectorButton.setAttribute('hidden', true);
      eventInspectorButton.removeAttribute('hidden');
    }

    keyCodesTableButton.setAttribute('hidden', true);
  } else {
    setTimeout(() => input.focus());

    if (nextSectionRef === KEY_INSPECTOR_REF) {
      initKeyInspector();
    }

    // NAV allows: Any inspector -> Keycodes table:
    keyInspectorButton.setAttribute('hidden', true);
    eventInspectorButton.setAttribute('hidden', true);
    keyCodesTableButton.removeAttribute('hidden');

    const currentSection = currentSectionRef === '' ? keyInspectorSection : eventInspectorSection;
    const currentSectionMaybeConsoleElement = currentSection.children[1];

    if (currentSectionMaybeConsoleElement !== consoleElement) {
      currentSection.insertBefore(consoleElement, currentSectionMaybeConsoleElement);
    }
  }
}

export function initNavigation() {
  updateNavigation('', getHashSectionRef());

  if (window.innerWidth - document.body.offsetWidth > 0) {
    // If scrollbars take space (like in Windows), we add custom styles for them on the table horizontal scroll:
    document.getElementById('propertiesSection').classList.add('hasHorizontalScrollbar');
  }
}

window.addEventListener('hashchange', () => {
  const hashSectionRef = getHashSectionRef();

  if (hashSectionRef === currentSectionRef) return;

  updateNavigation(currentSectionRef, hashSectionRef);
});

keyInspectorButton.addEventListener('click', () => {
  updateNavigation(getHashSectionRef(), KEY_INSPECTOR_REF);
});

eventInspectorButton.addEventListener('click', () => {
  updateNavigation(getHashSectionRef(), EVENT_INSPECTOR_REF);
});

keyCodesTableButton.addEventListener('click', () => {
  updateNavigation(getHashSectionRef(), KEYCODES_TABLE_REF);
});

showMoreButton.addEventListener('click', () => {
  updateNavigation(getHashSectionRef(), EVENT_INSPECTOR_REF);
});

showLessButton.addEventListener('click', () => {
  updateNavigation(getHashSectionRef(), KEY_INSPECTOR_REF);
});
